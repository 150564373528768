.App {
  background-color: #ffffff;
  font-family: "Cairo";
}

.content {
  background-color: #f0f2f5;
  padding: 24px;
}

.layout-first {
  margin-top: 64px;
}

.layout-second {
  margin-left: 20%;
}
.side-nav-menu .ant-menu-submenu-title,
.side-nav-menu .ant-menu-item {
  font-size: 16px; /* Adjust the size as needed */
  line-height: 24px; /* Optional: Adjust line height for better spacing */
  font-family: "Cairo";
}
.ant-layout-sider-children {
  background-color: #ffffff;
}

/* header*/
.ant-layout-header.css-dev-only-do-not-override-tpassh {
  background-color: #ffffff;
}

.header-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #006eff;
  letter-spacing: 0.05em;
  margin-top: 17px;
}

.spacer {
  flex: 1;
}
.header-features {
  display: flex;
  align-items: center;
  gap: 16px;
}
.Table .ant-table-cell,
.Table .ant-table-thead > tr > th {
  text-align: center;
}

.title-container {
  color: #006eff;
  padding: 0px 0;
  text-align: center;
  margin-bottom: 30px;
}

.product-sourcing-title {
  letter-spacing: 0.05em;
  font-family: "Roboto Slab", serif;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-out;
  margin-left: 15px;
  margin-top: 17px;
}

.title-icon {
  margin-right: 16px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  25% {
    opacity: 0.25;
    transform: translateY(-10px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-5px);
  }
  75% {
    opacity: 0.75;
    transform: translateY(-2.5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.daily-deals-buttons,
.product-sourcing-buttons {
  margin-bottom: 1rem;
}
.button-settings {
  margin-right: 0.5rem;
}

.daily-deals-search,
.product-sourcing-search {
  margin-bottom: 1rem;
  max-width: 20rem;
}

.daily-deals-reset-button,
.product-sourcing-reset-button {
  padding-top: 1rem;
}

.button-tour {
  margin-left: 10px;
  position: relative;
  overflow: hidden;
  animation: glowing 1.5s 3;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px rgba(0, 126, 255, 0.4);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 126, 255, 1);
  }
  100% {
    box-shadow: 0 0 5px rgba(0, 126, 255, 0.4);
  }
}
.upload-container,
.select-columns-container,
.confirmation-container {
  padding: 2rem;
  background-color: #ffffff;
  font-family: "Cairo", sans-serif;
  text-align: center;
  min-height: 100vh;
}
.confirmation-back-home-button {
  margin-top: 2rem;
}

ant-form-item {
  text-align: left;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  padding: 0 24px;
  display: flex;
  align-items: center;
  height: 64px;
}

.header-logo {
  width: 100px;
  height: 50px;
}

.header-button {
  color: #006eff;
  background-color: #f0f0f0;
  border-radius: 50%;
  width: 3rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Footer.css */

/* Style the Ant Design Footer component */
.custom-footer {
  background-color: #ffffff; /* White background */
  border-top: 1px solid #f0f0f0; /* Light gray top border */
  padding: 10px 20px; /* Reduced vertical and horizontal padding */
  position: fixed; /* Fix the footer at the bottom */
  width: 100%; /* Full width */
  bottom: 0; /* Align to the bottom */
  left: 0; /* Align to the left */
  z-index: 1000; /* Ensure it's above other elements */
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

/* Container for footer content */
.footer-content {
  display: flex;
  flex-direction: row; /* Arrange items horizontally */
  justify-content: space-between; /* Space between items */
  align-items: center; /* Center items vertically */
  max-width: 1200px; /* Optional: Max width for larger screens */
  margin: 0 auto; /* Center the container */
}

/* Style for the copyright text */
.footer-text {
  font-size: 12px; /* Reduced font size */
  color: #595959; /* Dark gray text */
}

/* Style for the email link */
.footer-email a {
  font-size: 12px; /* Reduced font size */
  color: #1890ff; /* Ant Design's primary blue */
  text-decoration: none; /* Remove underline */
}

.footer-email a:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .footer-content {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: flex-start; /* Align items to the start */
  }

  .footer-text,
  .footer-email a {
    font-size: 14px; /* Slightly larger font for readability */
  }

  .custom-footer {
    padding: 15px 20px; /* Adjust padding for better spacing */
  }
}

/* src/components/PricingPlans.css */

.pricing-plans-container {
  padding: 50px 20px;
  background-color: #f0f2f5; /* Light gray background */
  text-align: center;
}

.tour-trigger {
  text-align: center;
  margin-bottom: 30px;
}

.tour-trigger .ant-btn {
  font-size: 16px;
  padding: 10px 20px;
}

.pricing-title {
  margin-bottom: 40px;
  color: #001529; /* Ant Design's default text color */
}

.pricing-card {
  border-radius: 12px;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  height: 100%; /* Ensure all cards have equal height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff; /* White background for cards */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.featured {
  border: 2px solid #1890ff; /* Ant Design primary color */
}

.featured-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: bold;
}

.plan-title {
  color: #1890ff;
  margin-bottom: 10px;
  /* Add some padding or margin if needed to prevent overlapping with the Tour tooltip */
}

.plan-price {
  font-size: 24px;
  margin: 10px 0;
  display: block;
  color: #000000; /* Black color for price */
}

.plan-trial {
  font-size: 14px;
  color: #8c8c8c;
  margin-bottom: 20px;
}

.plan-features {
  margin-bottom: 20px;
  text-align: left;
  flex-grow: 1; /* Pushes the button to the bottom */
  padding-left: 0; /* Remove default padding */
}

.plan-feature-item {
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0; /* Separator between features */
  display: flex;
  align-items: center;
}

.plan-feature-item:last-child {
  border-bottom: none; /* Remove separator from the last item */
}

.feature-icon {
  margin-right: 8px;
  font-size: 16px;
  width: 24px; /* Fixed width */
  text-align: center; /* Center the icon within the fixed width */
}

.feature-icon.included {
  color: #52c41a; /* Green color for included features */
}

.feature-icon.excluded {
  color: #ff4d4f; /* Red color for excluded features */
}

.feature-text.included-text {
  color: #595959; /* Dark gray for included features */
}

.feature-text.excluded-text {
  color: #8c8c8c; /* Light gray for excluded features */
}

.plan-button {
  border-radius: 4px;
  height: 40px;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.plan-button-primary {
  background-color: #1890ff;
  border-color: #1890ff;
  color: #ffffff;
}

.plan-button-default {
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
}

.plan-button-default:hover,
.plan-button-default:focus {
  background-color: #fafafa;
  border-color: #40a9ff;
  color: #1890ff;
}

.plan-button-primary:hover,
.plan-button-primary:focus {
  background-color: #40a9ff;
  border-color: #40a9ff;
  color: #ffffff;
}

/* Footer Note */
.footer-note {
  display: block;
  margin-top: 30px;
  font-size: 14px;
  color: #8c8c8c;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .pricing-plans-container {
    padding: 30px 10px;
  }

  .tour-trigger .ant-btn {
    font-size: 14px;
    padding: 8px 16px;
  }

  .plan-price {
    font-size: 20px;
  }

  .plan-button {
    font-size: 14px;
    height: 35px;
  }

  .featured-tag {
    top: 5px;
    right: 5px;
    font-size: 12px;
  }

  .plan-feature-item {
    padding: 6px 0;
  }

  .feature-text {
    font-size: 13px;
  }
}
.converter-input {
  width: 30%;
  margin-right: 1rem;
}
